(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.matches.controller:MatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.matches')
    .controller('MatchesCtrl', MatchesCtrl);

  function MatchesCtrl(AclService,Matches, townHalls, currentSeason, $mdToast, $filter, $scope, $state) {
    var vm = this;
    vm.currentSeason = angular.copy(currentSeason);
    
    if( AclService.isAdmin()){
        townHalls.unshift({id:9999, name:'Tots', email:'', phoneNumber:0});
    }
    vm.townhalls = angular.copy(townHalls);
    vm.currentSeason.iniDate = moment(vm.currentSeason.iniDate).toDate();
    vm.currentSeason.endDate = moment(vm.currentSeason.endDate).toDate();

    //vm.iniDate = (angular.isUndefined($state.current.data.initDate)) ? vm.currentSeason.iniDate : $state.current.data.initDate;
    //vm.endDate = (angular.isUndefined($state.current.data.endDate)) ? vm.currentSeason.endDate : $state.current.data.endDate;
    var d = new Date();
    //d.setDate(d.getDate() - 7);
    vm.iniDate=d;
     var d2 = new Date();
    d2.setDate(d.getDate() + 7);
    vm.endDate=d2;
    
    vm.paginationLabel = {
      page: $filter('translate')('PAGE'),
      rowsPerPage: '',
      of: $filter('translate')('OF')
    };

    vm.matches = [];
    vm.selected = [];
    vm.select = undefined;

    vm.openMenu = function ($mdMenu, event) {
      $mdMenu.open(event);
    };

    if (vm.townhalls.length === 1) {
      vm.selectedTownHall = vm.townhalls[0];
      $state.current.data.townHall = vm.selectedTownHall;
    } else {
      vm.selectedTownHall = ($state.current.data.townHall) ? $state.current.data.townHall : undefined;
    }
    vm.currentPage = 1;
    vm.matchesPerPage = 1500;
    vm.queryOrder = 'championship';
    vm.sort = 'championship';

    vm.applyFilters = applyFilters;
    vm.reorder = reorder;
    vm.getNewPage = getNewPage;

    function applyFilters() {
      vm.isFiltered = true;
      query(vm.selectedTownHall.id, vm.iniDate, vm.endDate, vm.currentPage, vm.matchesPerPage, vm.sort);
    }

    function reorder(order) {
      vm.sort = order;
      query(vm.selectedTownHall.id, vm.iniDate, vm.endDate, vm.currentPage, vm.matchesPerPage, vm.sort);
    }

    function getNewPage(page, limit) {
      vm.currentPage = page;
      vm.matchesPerPage = limit;
      query(vm.selectedTownHall.id, vm.iniDate, vm.endDate, vm.currentPage, vm.matchesPerPage, vm.sort);
    }

    function query(townHallId, iniDate, endDate, page, size, sort) {
      var parameters = {
        townHall: townHallId,
        initDate: moment(iniDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        page: page,
        size: size
      };
      //Optional parameters
      if (angular.isDefined(sort)) {
        parameters.sort = sort;
      }
      Matches.get(parameters, function (data) {
          _.remove(vm.matches);
          angular.forEach(data.matches, function (match) {
            var localTeam = findItemById(data.teams, match.localTeamId);
            var visitorTeam = findItemById(data.teams, match.visitorTeamId);
            var facility = findItemById(data.facilities, match.facilityId);
            var championship = findItemById(data.championships, match.championshipId);
            vm.matches.push({
              town: angular.isDefined(facility) ? facility.town : '-',
              matchDay: match.matchDay,
              localTeam: localTeam,
              visitorTeam: visitorTeam,
              date: moment(match.date).format('L'),
              time: match.time === null ? '--:--' : match.time,
              facility: facility,
              championship: championship
            });
          });
          vm.maxMatches = data.page.count;
        },
        function (error) {
          var message = angular.isDefined(error.data.message) ? error.data.message : 'TOAST_ERROR';
          $mdToast.show(
            $mdToast.simple()
              .textContent($filter('translate')(message))
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }

    $scope.$watch('vm.iniDate', function (newDate) {
      $state.current.data.initDate = newDate;
    });

    $scope.$watch('vm.endDate', function (newDate) {
      $state.current.data.endDate = newDate;
    });

    $scope.$watch('vm.selectedTownHall', function (selectedTownHall) {
      $state.current.data.townHall = selectedTownHall;
    });
 $scope.exportToExcel= ((function() {
          var uri = 'data:application/vnd.ms-excel;base64,'
            , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>'
            , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
            , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }
          return function(table, name) {
            table = document.getElementById(table);
            
            var ctx = {worksheet: name || 'Report', table: table.innerHTML};
            window.location.href = uri + base64(format(template, ctx));
          }
        })())
        


  }
  
  
  
}());
